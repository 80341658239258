<template>
  <VNavigationDrawer v-model="drawer" class="white" app right temporary>
    <BaseButton
      icon
      fab
      small
      color="treps-navy"
      md-icon="close"
      aria-label="Close Menu"
      @click="$store.commit('nav/TOGGLE_DRAWER')"
    ></BaseButton>
    <VList>
      <VListTile
        v-for="route of routes"
        :key="route.title"
        :to="route.to"
        @click.native.stop="currPageAction"
      >
        <VListTileContent>
          <VListTileTitle
            class="r-headline text-uppercase font-weight-medium treps-navy--text"
            >{{ route.title }}</VListTileTitle
          >
        </VListTileContent>
      </VListTile>

      <BaseButton
        class="ml-0 mt-3"
        aria-label="Make a Reservation"
        href="https://www.opentable.com/restaurant/profile/1081921/reserve?restref=1081921&lang=en-US&ot_source=Restaurant%20website"
        color="treps-blue"
        large
        >Book a Table</BaseButton
      >
      <BaseButton
        v-if="theLocation && formattedPhone(theLocation)"
        class="ml-0 mt-3"
        aria-label="Call Us Now"
        :href="`tel:${formattedPhone(theLocation)}`"
        color="treps-blue"
        large
        >Call Us</BaseButton
      >
    </VList>
  </VNavigationDrawer>
</template>

<script>
import { mapGetters } from 'vuex'

import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'MobileNavBar',
  components: {},
  data() {
    return {
      showMenusDropdown: false,
      routes: [
        {
          name: 'home',
          title: 'Home',
          to: { path: '/' },
          position: 'center',
          exact: true,
        },
        {
          name: 'menu',
          title: 'Menu',
          to: { path: '/locations/treps' }, // TODO (ESS) : Make this loc slug dynamic
          position: 'center',
        },
        {
          title: 'Contact & Team',
          to: { path: '/contact-team' },
          exact: true,
          position: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('locations', ['getLocationBySlug']),
    drawer: {
      get() {
        return this.$store.state.nav.isDrawerOpen
      },
      set(val) {
        this.$store.commit('nav/SET_DRAWER', val)
      },
    }, // drawer
    theLocation() {
      return this.getLocationBySlug('treps')
    }, // theLocation
  },
  beforeMount() {
    // Set the drawer to closed on load
    this.$store.commit('nav/SET_DRAWER', false)
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
    currPageAction() {
      this.$store.commit('nav/SET_DRAWER', false)
    }, // currPageAction
  },
}
</script>

<style lang="scss">
@import '@design';

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
  }
}
</style>
